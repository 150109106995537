import { useTranslation } from "react-i18next";

import { IOptionProps } from "components/shared";
import useAppDispatch from "hooks/useAppDispatch";
import useReduxSelector from "hooks/useReduxSelector";
import { useGetIndustryTypesQuery } from "store/api/referenceDataApi";
import { resetCurrentFilters, setCurrentFilters } from "store/companySlice";
import { CompanyListFilterDto } from "types/dtos/company/CompanyListFilterDto";

export interface ICompanyListOptions {
    industryTypes: IOptionProps[];
    companyTypes: IOptionProps[];
    serviceOrientationTypes: IOptionProps[];
    statuses: IOptionProps[];
}

const useCompanyListFilters = () => {
    const dispatch = useAppDispatch();
    const { filters } = useReduxSelector((state) => state.companyState);
    const { data: industryTypes } = useGetIndustryTypesQuery(undefined);

    const { t } = useTranslation();

    const availableStatuses: IOptionProps[] = [
        { value: "Active", label: t("companyList.companies.displayValues.status.active") },
        { value: "Inactive", label: t("companyList.companies.displayValues.status.inactive") },
    ];

    const availableCompanyTypes: IOptionProps[] = [
        { value: "Buyer", label: t("companyList.companies.displayValues.companyTypes.buyer") },
        { value: "Supplier", label: t("companyList.companies.displayValues.companyTypes.supplier") },
    ];

    const availableServiceOrientation: IOptionProps[] = [
        { value: "Standard", label: t("companyList.companies.displayValues.serviceOrientationTypes.Standard") },
        { value: "Technology", label: t("companyList.companies.displayValues.serviceOrientationTypes.Technology") },
        { value: "FullService", label: t("companyList.companies.displayValues.serviceOrientationTypes.FullService") },
    ];

    const setReduxFilters = (filterValues: CompanyListFilterDto) => {
        dispatch(setCurrentFilters(filterValues));
    };

    return {
        currentFilters: filters,
        setCurrentFilter: setReduxFilters,
        resetCurrentFilter: () => dispatch(resetCurrentFilters()),
        options: {
            industryTypes:
                industryTypes?.data.map((d) => ({
                    value: d.id.toString(),
                    label: d.name ?? "",
                })) ?? [],
            statuses: availableStatuses,
            companyTypes: availableCompanyTypes,
            serviceOrientationTypes: availableServiceOrientation,
        },
    };
};

export default useCompanyListFilters;
